import React from "react"
import loadable from '@loadable/component'

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SEO from '../components/SEO'

const Logo = loadable(() => import("../components/Logo"));
const Menu = loadable(() => import('../components/Menu'));
const App = loadable(() => import('../components/App'));
const Content = loadable(() => import('../components/Content'));
const Section = loadable(() => import('../components/Section'));
const InnerSection = loadable(() => import('../components/InnerSection'));

export default function Home() {
  const breakpoints = useBreakpoint();

  return (
    <main>
      <SEO/>
      <Logo/>
      <Menu/>
      {
        (() => {
          if(breakpoints.sm) {
            return <App/>
          }
        })()
      }
      <Content>
        <Section title='Política de Remoção de Dados' id='how'>
          <InnerSection style={{margin: '70px 25px 100px 25px'}}>
            <h2>
              Término do Tratamento dos Dados
            </h2>
            <p>
            À URDOG, é permitido manter e utilizar os dados pessoais do USUÁRIO e de seu “PET” durante todo o período contratualmente firmado, para as finalidades relacionadas nesse termo, e, ainda, após o término da contratação para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei n° 13.709/2018.
            </p>

            <br />
            <h2>
              Direito de Revogação do Consentimento
            </h2>
            <p>
              O USUÁRIO poderá revogar seu consentimento, a qualquer tempo, por e-mail (contato@urdog.com.br) ou por carta escrita, conforme o artigo 8°, § 5°, da Lei n° 13.709/2020.<br />
              O USUÁRIO fica ciente de que a URDOG poderá permanecer utilizando os dados para as seguintes finalidades:<br />
              <ul>
                <li>Para cumprimento de obrigações decorrentes da legislação brasileira.</li>
                <li>Para cumprimento, pela URDOG, de obrigações impostas por órgãos de fiscalização;</li>
                <li>Para o exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
                <li>Para a proteção da vida ou da incolumidade física do titular ou de terceiros;</li>
                <li>Quando necessário para atender aos interesses legítimos da URDOG ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do USUÁRIO que exijam a proteção dos dados pessoais.</li>
              </ul>
            </p>
          </InnerSection>
        </Section>
      </Content>
    </main>
  )
}